import React, { useContext, useState, useRef, useEffect } from "react";
import Services from "../../../services";
import { SocketContext } from "services/SocketProvider";

const SendMessage = ({ userId, chatId, scrollToBottom }) => {
  const [message, setMessage] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const socket = useContext(SocketContext);
  const inputRef = useRef(null);

  useEffect(() => {
    if (message.length > 0) {
      setShowPlaceholder(false);
    } else {
      setShowPlaceholder(true);
    }
  }, [message]);

  const sendMessage = async () => {
    if (!message.trim()) return;

    if (socket) {
      socket.emit("support-message", {
        text: message,
        chatId: chatId,
      });
    } else {
      console.error("Socket is not defined, cannot send message");
    }
    setMessage("");
    inputRef.current.innerHTML = "";
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const br = document.createElement("br");
      range.deleteContents();
      range.insertNode(br);
      range.collapse(false);

      const doubleBr = document.createElement("br");
      range.insertNode(doubleBr);

      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  const handleInput = (e) => {
    setMessage(e.target.innerText);
  };

  const bottomCenteredStyle = {
    position: "fixed",
    bottom: "1px",
    left: "56%",
    transform: "translateX(-50%)",
    width: "86%",
    maxWidth: "100%",
    zIndex: 1000,
  };

  const chatListStyle = {
    paddingBottom: "100px",
  };

  return (
    <>
      <div className="col-12 col-xl-10 me-auto" style={bottomCenteredStyle}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            sendMessage();
          }}
          className="d-flex align-items-center py-3 px-4"
        >
          <div
            ref={inputRef}
            contentEditable
            onKeyDown={handleKeyDown}
            onInput={handleInput}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            className="form-control shadow-lg"
            style={{
              minHeight: "40px",
              display: "flex",
              alignItems: "center",
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
              wordBreak: "break-word",
              borderRadius: "8px",
              borderColor: "gray",
              backgroundColor: "#F3F4F6",
              marginLeft: "30px",
              position: "relative",
              padding: "10px",
            }}
          >
            {!message && !isFocused && (
              <span
                className="text-muted"
                style={{
                  position: "absolute",
                  left: "12px",
                  pointerEvents: "none",
                  userSelect: "none",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                Start typing
              </span>
            )}
          </div>

          <input type="hidden" value={message} />

          <button type="submit" className="btn btn-light ms-2">
            <img height={28} src="/images/chat/snd.png" alt="icon" className="pointer" />
          </button>
        </form>
      </div>
    </>
  );
};

export default SendMessage;
